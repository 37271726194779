import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoLight from './logo-light.svg';
import logoDark from './logo-dark.svg';
import whatsapp from '../assets/icns/whatsapp-icn.svg';

import menuDark from '../assets/icns/menu-dark.svg';
import menuLight from '../assets/icns/menu-light.svg';

import Menu from './Menu';
import MenuServices from './ServicesMenu';
import MenuWorks from './WorksMenu';


const Header = ({ isDarkBackground }) => {

  const logoSrc = isDarkBackground ? logoLight : logoDark ;

  const menuIcn = isDarkBackground ? menuLight : menuDark ;


  const redirectToProposal = () => {
    window.location.href = '/contact';
  };

  const redirectToWorks = () => {
    window.location.href = '/works';
  };

  const redirectToDev = () => {
    window.location.href = '/development';
  };



  const redirectToAbout = () => {
    window.location.href = '/about';
  };



    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMenuServicesOpen, setIsMenuServicesOpen] = useState(false);
    const [isMenuWorksOpen, setisMenuWorksOpen] = useState(true);

    

    const openMenu = () => {
        setIsMenuOpen(true);
        document.body.style.overflow = 'hidden'; 
    };

    const closeMenu = () => {
      setIsMenuOpen(false);
      document.body.style.overflow = 'auto'; 
    };

    const openServicesMenu = () => {
      setIsMenuServicesOpen(true);
      document.body.style.overflow = 'hidden';
    };

    const closeServicesMenu = () => {
      setIsMenuServicesOpen(false);
      document.body.style.overflow = 'auto';

    };

    const openWorksMenu = () => {
      setisMenuWorksOpen(true);
      document.body.style.overflow = 'hidden';
    };

    const closeWorksMenu = () => {
      setisMenuWorksOpen(false);
      document.body.style.overflow = 'auto';

    };





  return (
    <div className='container-fluid header mx-auto'>
            {isMenuOpen && <Menu isDarkBackground={true} setIsMenuOpen={setIsMenuOpen} />}

      <div className='row align-items-center'>
            <Link to="/" className='col-6 col-md-3'>
                  <img className='logo' src={logoSrc} alt="Logo"/>
            </Link>


{/* Main */}


        <div className="col-6 d-flex d-md-none justify-content-end" onClick={isMenuOpen ? closeMenu : openMenu}>
              <img className='menu-icn pointer mt-1' src={menuIcn} alt=""/>
          </div>

        <div className='col-6 col-md-9 d-none d-md-flex justify-content-end'>
          <div className='d-inline-flex float-end m-1 '>

{/* Main */}

              <p className='fs-18 fw-300 pointer me-4'
              onMouseOver={openWorksMenu} onMouseLeave={closeWorksMenu} onClick={redirectToWorks}
              style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}>
                Works</p>

              <p onClick={redirectToAbout} className='fs-18 fw-300 pointer me-4' style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}>
                About</p>

            <p className='fs-18 fw-700 pointer ' style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }} onClick={redirectToProposal}>
               Contact</p>



{/* Development */}

               {/* <p className='fs-16 fw-400 pointer pe-3'
                onMouseOver={openWorksMenu} onMouseLeave={closeWorksMenu} onClick={redirectToWorks}
                style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}>
                  Works</p>

               <p className='fs-16 fw-400 pointer pe-3'
                onMouseOver={openWorksMenu} onMouseLeave={closeWorksMenu} onClick={redirectToWorks}
                style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}>
                  Services</p>

                <p onClick={redirectToAbout} className='fs-16 fw-400 pointer pe-3' style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}>
                  Technologies</p>

                <p className='fs-16 fw-400 pointer ' style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }} onClick={redirectToProposal}>
                  Contact</p>
 */}


               {/* <a href="https://api.whatsapp.com/send?phone=972528450488" target='blank'
               style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
               className='fs-16 fw-400 pointer'>
                  <img width={'30px'} src={whatsapp}/>
                  </a> */}

                  

              </div>
            </div>
          </div>
        </div>
      );
    }
  
  export default Header;
  