import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';


import ServicesPage from "./pages/ServicesPage";
import DevelopmentPage from "./pages/DevelopmentPage";
import HomePage from "./pages/HomePage";



function App() {
  return (
    <div className=''>
    <BrowserRouter>
      <Routes>


        <Route index element={<HomePage/>} />
        <Route path="/development"  element={<DevelopmentPage/>} />
        <Route path="/services"  element={<ServicesPage/>} />
        <Route path="/technologies"  element={<DevelopmentPage/>} />

      </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
