import React, { useState, useEffect } from 'react';
import Header from '../header/Header';
import { Link } from 'react-router-dom';

import Footer from '../footer/Footer';
import VideoLoader from '../functions/VideoURLLoader';






const HomePage = () => {
    const [title, setTitle] = useState(['fezzik is a global creative Production House that brings brands, stories, and experiences to life through art, design, and technology.']);
    const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % title.length);
      }, 2000); 
      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }, [title]);
  


    const videoList = [
        { folderName: 'fezzik', fileName: 'fezzik_emojies_01.mp4' },
    
      ];


    const redirectToWorks = () => {
        window.location.href = '/works';
      };
      const redirectToContact = () => {
        window.location.href = '/contact';
      };
    const redirectToOtterize = () => {
        window.location.href = '/works/otterize';
      };
    const redirectToNavina = () => {
        window.location.href = '/works/navina';
      };
    const redirectToArrows = () => {
        window.location.href = '/works/arrows';
      };
    const redirectToSpark = () => {
        window.location.href = '/works/spark';
      };
      const redirectToVayyar = () => {
        window.location.href = '/works/vayyar';
      };
      const redirectToClariter = () => {
        window.location.href = '/works/clariter';
      };
      const redirectToAstrix = () => {
        window.location.href = '/works/astrix';
      };
      const redirectToGeoxITC = () => {
        window.location.href = '/works/geoxITC';
      };


      

  return (
    <div className='light-background row container maxWidth-1920 mx-auto'>
        <Header/>
        <div className='header-height'></div>

            <div className='row align-items-top h-70 mb-4'>
             
                <div className='col-lg-12 col-md-6 col-sm-12 col-xs-12 px-sm-4'>
                    <h1 className='fw-300 text-dark capitalize'>
                        Power Your Business Growth with our creative guidance
                        {/* Power Your Business Growth with our creative guidance */}
                    </h1>
                    <p className='mt-4 fw-200 fs-30'>
                        By leveraging state-of-the-art visual experiences, innovative thinking, creative problem-solving, and data-driven insights, we create an excellent call to actions.
                    </p>
                
                    <div className='mt-3 d-inline-flex'>
                    <Link to="/contact" className=''>
                        <a className='m-0 p-0 text-dark capitalize fs-22 fw-600'>
                        &nbsp;Schedule a call&nbsp;↗
                        </a>
                        {/* <img className='ps-2' width="30px" src={arrowIcn}/> */}
                    </Link>
                    </div>
                </div>       

                {/* <div className='col justify-content-end text-right align-right'>
                    <div className='float-end d-flex'>
                          <VideoLoader video={videoList[0]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="95%" />
                      </div>
                </div>     */}

            </div>





            <div className='row mb-5 px-sm-4'>
                <div className='col-12'>
                    <div className='border-bottom-dark py-2'>
                        <h6 className='fw-200 capitalize'>
                            Services we provide
                        </h6>
                    </div>
                    <div className='border-bottom-dark py-2'>
                        <h6 className=' fw-400'>
                            Video Production
                        </h6>
                    </div>
                    <div className='border-bottom-dark py-2'>
                        <h6 className=' fw-400'>
                            Animation Production
                        </h6>
                    </div>
                    <div className='border-bottom-dark py-2'>
                        <h6 className=' fw-400'>
                            Service Design
                        </h6>
                    </div>
                    <div className='border-bottom-dark py-2'>
                        <h6 className=' fw-400'>
                            Brand Design
                        </h6>
                    </div>
                    <div className='border-bottom-dark py-2'>
                        <h6 className=' fw-400'>
                            Product Design
                        </h6>
                    </div>
                    <div className='border-bottom-dark py-2'>
                        <h6 className=' fw-400'>
                            Service Design
                        </h6>
                    </div>
                    <div className='border-bottom-dark py-2'>
                        <h6 className=' fw-400'>
                            Game Development
                        </h6>
                    </div>
                    <div className='border-bottom-dark py-2'>
                        <h6 className=' fw-400'>
                            Interface Development
                        </h6>
                    </div>
            </div>
        </div>




        <div className='row mt-4 mb-4 px-sm-2'>
                <div className='col-10 mx-auto'>
                    <div className='text-center pt-5 pb-5'>
                        <h2 className='fw-400'>
                        Elevate your brand to the next level with our professional services! Our expertly crafted video, design and development productions not only grab attention but also enhance user experience and engagement.
                        </h2>
                            <a className='d-inline-flex mt-4 fs-24 fw-400 px-4 py-2 pointer capitalize text-dark'
                            onClick={redirectToContact} 
                            >
                             Schedule a call&nbsp;↗
                            </a>
                    </div>



{/* 
                <div className='row align-items-end'>
                    <div className='col-4 dark-background text-light m-2'>
                        <div className='my-5 py-5'></div>
                        <h4 className='fs-32 fw-200'>Healthcare</h4>
                    </div>
                    <div className='col-4 dark-background text-light m-2'>
                        <div className='my-5 py-5'></div>
                        <h4 className='fs-32 fw-200'>Technology</h4>
                    </div>
                    <div className='col-4 dark-background text-light m-2'>
                        <div className='my-5 py-5'></div>
                        <h4 className='fs-32 fw-200'>Education</h4>
                    </div>
                    <div className='col-4 dark-background text-light m-2'>
                        <div className='my-5 py-5'></div>
                        <h4 className='fs-32 fw-200'>Sustainable</h4>
                    </div>
                    <div className='col dark-background text-light m-2'>
                        <div className='my-5 py-5'></div>
                        <h4 className='fs-32 fw-200'>Healthcare</h4>
                    </div>
                </div> */}


                </div>
            </div>


        




            <div className='row mb-5 mt-5 px-5'>
                <div className='col-12'>
                    <div className='pt-5 pb-5'>
                        <h1 className='fw-500 capitalize'>
                            Behind every project stand people. Together, we turn projects into trusting partnerships through our shared values & commitment
                        </h1>
                    </div>
                </div>
            </div>


            <div className='row px-4'>
               <div className='col-md col-sm col-xs-12 mt-3'>
                <p className='fs-18 m-0 p-0 fw-400'>Reach out</p>
                  <a className='fs-32 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
                    <br></br>
                        <a className='fs-32 text-dark fw-500' href="https://api.whatsapp.com/send?phone=972528450488">Whatsapp&nbsp;↗</a>
               </div>
               </div>

        <div className='mb-3'>
            <Footer/>
        </div>

   
        </div>

     
  );
};

export default HomePage;
